import { graphql } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"

import Layout from "src/layout"
import { Section } from "src/layout/styled"
import { COLORS, FONTS, LAYOUT } from "src/layout/constants"
import RichText from "src/components/RichText"
import InstitutionIcon from "src/svg/institution.svg"
import SearchIcon from "src/svg/search.svg"
import { formatDate } from "src/helpers/date"

const Columns = styled(Section)`
  @media (min-width: 800px) {
    display: flex;
    align-items: flex-start;
  }
`
const Left = styled.section`
  order: 1;
  flex: 1;
  p {
    white-space: pre-line;
  }
`
const Intro = styled.p`
  font-size: 2em;
`
const BottomTitle = styled.h5`
  font-family: ${FONTS.title};
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
  margin: ${LAYOUT.margin * 2}px 0 0;
  color: ${COLORS.white};
  line-height: 1.3em;
  span {
    box-shadow: ${COLORS.translucentSherpa} 10px 0px 0px 0px, ${COLORS.translucentSherpa} -10px 0px 0px 0px;
    background-color: ${COLORS.translucentSherpa};
  }
`
const About = styled.div`
  font-weight: bold;
`
const Links = styled.div``
const Right = styled.section`
  order: 2;
  flex: 0 1 350px;
  .gatsby-image-wrapper {
    margin: ${LAYOUT.margin}px 0;
    width: 100% !important;
    filter: grayscale();
  }
  @media (max-width: 799px) {
    .gatsby-image-wrapper {
      height: calc(100vw - ${LAYOUT.margin * 2}px) !important;
      margin: 25px 0 0;
    }
  }
  @media (min-width: 800px) {
    margin-left: ${LAYOUT.margin * 2}px;
  }
`
const Activities = styled.h4`
  text-transform: uppercase;
  font-family: ${FONTS.title};
  margin: 0 0 8px;
  &:last-of-type {
    margin-bottom: 20px;
  }
`
const Block = styled.div`
  border: 5px solid ${COLORS.sherpa};
  margin-bottom: -5px;
  padding: 3px 6px 4px;
  min-height: 90px;
  display: flex;
  align-items: center;
  h1,
  h2,
  h3 {
    text-transform: uppercase;
    margin: 0;
  }
  h1 {
    font-family: ${FONTS.text};
    font-size: 2em;
  }
  h2 {
    font-size: 1em;
  }
  h3,
  p {
    font-size: 0.8em;
    margin-right: 12px;
    flex: 1;
  }
  svg {
    flex: 0 0 auto;
    height: 24px;
    margin: 0 5px 0 2px;
  }
`
const Dates = styled.div`
  border-left: 5px solid ${COLORS.sherpa};
  align-self: stretch;
  margin: -3px -6px -4px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    width: 100%;
    height: 5px;
    background-color: ${COLORS.sherpa};
  }
  p {
    text-transform: uppercase;
    font-weight: bold;
    margin: 12px 0;
  }
`

interface Data {
  mission: Mission
}

const MissionPage: GatsbyPage<Data, Activity> = ({ data }) => {
  const { intro, text, activities, title, subtitle, client, methods, start, end, photo, about, links } = data.mission

  return (
    <Layout theme="pink" title={title} description={subtitle}>
      <Columns as="div">
        <Right>
          {activities?.map((activity) => (
            <Activities key={activity.id}>{activity.title}</Activities>
          ))}
          <Block>
            <h1>{title}</h1>
          </Block>
          <Block>
            <h2>{subtitle}</h2>
          </Block>
          <Block>
            <InstitutionIcon />
            <h3>{client}</h3>
          </Block>
          <Block>
            <SearchIcon />
            <p>
              {methods && (
                <>
                  <strong>Méthodes :</strong> {methods.join(", ")}
                </>
              )}
            </p>
            <Dates>
              <p>{formatDate(start, "MMM yyyy")}</p>
              <div>&nbsp;</div>
              <p>{formatDate(end, "MMM yyyy")}</p>
            </Dates>
          </Block>
          <Image fixed={photo.fixed} />
        </Right>
        <Left>
          <Intro>{intro.intro}</Intro>
          <RichText node={text} />
          {about && (
            <>
              <BottomTitle>
                <span>En savoir plus</span>
              </BottomTitle>
              <About>
                <RichText node={about} />
              </About>
            </>
          )}
          {links?.length > 0 && (
            <>
              <BottomTitle>
                <span>Ils parlent de l’étude</span>
              </BottomTitle>
              <Links>
                {links.map(({ name, url }, index) => (
                  <p key={index}>
                    <strong>{name}</strong>
                    <br />
                    <a href={url} target="_blank" rel="noopener">
                      {url}
                    </a>
                  </p>
                ))}
              </Links>
            </>
          )}
        </Left>
      </Columns>
    </Layout>
  )
}

export default MissionPage

export const query = graphql`
  query($id: String!) {
    mission: contentfulMission(contentful_id: { eq: $id }) {
      title
      subtitle
      client
      methods
      start
      end
      intro {
        intro
      }
      text {
        json
      }
      photo {
        fixed(width: 350, height: 350, quality: 75) {
          ...GatsbyContentfulFixed
        }
      }
      about {
        json
      }
      links {
        name
        url
      }
      activities: activity {
        id: contentful_id
        title
      }
    }
  }
`
